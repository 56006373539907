// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"P8sI5NcJdYFyOH3isr9R7"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import { SENTRY_DSN, SENTRY_ENABLED } from '@/constants';
import { sentryMonitoredPaths } from '@/utils/sentryConfig';
import * as Sentry from '@sentry/nextjs';

if (SENTRY_ENABLED) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
    beforeSend: (event) => {
      // Get the current route
      const route = window?.location?.pathname;

      // Only send events for monitored routes
      if (sentryMonitoredPaths.some(path => route?.startsWith(path))) {
        return event;
      }

      return null; // Drop the event
    },
  });
}
